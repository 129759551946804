import React, { useContext } from 'react';
//import {Link} from 'react-router-dom';
import {UserContext} from '../utils/usercontext';

const Footer = () => {
  
	const {user} = useContext(UserContext);
	var count = 0;
	if( user.user ) {
			count = 1;
	}
	return (
		<>
			{count ? (
				<>
					<div>
					</div>
				</>
			) : (
				<>
				</>
			)}
		</>
	);
}

export default Footer;