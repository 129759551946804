import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import { /*cookie,*/ login } from '../utils/protectedroutes';
import axios from 'axios';
import LoginForm from './components/login/login';
//import {getApi} from '../utils/functions';


export var LoginState = false;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      status: false,
      await: false,
      totp: false,
    };
    //this.state.bind(this);
    //this.status = this.state.bind(this);  
  };
  /*showOpen() {
    this.setState({ status: true });
  }*/
  async componentDidMount() {
    
  }
  getUser = (e) => {
    this.setState({await: true});
    e.preventDefault();
    const params = new URLSearchParams()
    params.append('uid', e.target.elements.uid.value)
    params.append('pwd', e.target.elements.pwd.value)
    //params.append('cookie', localStorage.getItem('cookie'))
    //if(this.state.totp === true)
    //params.append('totp', e.target.elements.totp.value)

    axios.post(`/api/v1/login`, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true
    })
    .then((res) => {
      console.log( res );
      /*if( res.data.error ) {
        if( res.data.error === "Recover" ) {
          window.location.href = '/recover';  
        } else {
          const element = res.data.error;
          ReactDOM.render( element, document.getElementById('error') );
          //cookie( res.data );
          //this.setState({await: false, totp: false});
        }
      } else {
        */
        login( res.data );
        window.location.href = '/';
      /*}*/
    })
    .catch(error => {
      console.log("Error");
      /*if (error.message === "Network Error"){
				
				setTimeout(_ => {
        	this.getUser(e);
      	}, 5000);
			}
      
      if( error.response.status === 403 ) {
        this.setState({await: false, totp: true});
      }*/
      //console.log( err );
    });

  }
  

  render() {
    return (
      <>
        <LoginForm getUser={this.getUser} Status={this.state.status} await={this.state.await} totp={this.state.totp} />
      </>
    );
  }
}
  
export default Login;
//export LoginState;
  
