import React/*, {useState}*/ from 'react';
import {Text} from 'react-native'
//import {Link} from 'react-router-dom';
import {useracc, getApi, postApi} from '../utils/functions';



//import Moment from 'react-moment';
import {UserContext} from '../utils/usercontext';

//import Accordion from 'react-bootstrap/Accordion';
//import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';

//const {hasCommandModifier} = KeyBindingUtil;
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';


//const Index = props => {
class NewShows extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
			page: 0,
			log: {},
			reason: '',
			waiting: false,
    };
		this.handleChange = this.handleChange.bind(this);
    this.Delete = this.Delete.bind(this);
		//this.NewShowAdd = this.NewShowAdd.bind(this);
		//this.NewShowDelete = this.NewShowDelete.bind(this);
		//this.NewShowConfirm = this.NewShowConfirm.bind(this);
  };

  //static contextType = UserContext;
  //loading:any = React.createRef();
  
  async componentDidMount() {
    //static contextType = AppContext;
    const value = this.context;

    //const [context, setContext] = useContext(UserContext);
    const getUser = await useracc();
    //console.log( getUser );
    //this.state.user = getUser;
    value.setUser( getUser );

    //console.log(this.context);

		var x = 0;
    if( this.props.match.params ) {
      if( this.props.match.params.id ) {
        x = parseInt( this.props.match.params.id );
      }
    }
		//console.log(x);
    //const [editorState, setEditorState] = useState('');
    const y = await getApi('/api/v1/details/'+x);
    //console.log( y.data.Log );
		//this.setState({newshows: x.data.NewShows});
		this.setState({ page: x, logs: y.data.Log});
  }

	handleChange(event) {
		var item = event.target.value;
    if( event.target.name === "reason" ) this.setState({reason: item});
	}

	Delete = async(e) => {
		this.setState({waiting: true});
    //this.NewShowConfirm(e, 1);
    const params = new URLSearchParams();
		params.append('id', this.state.page);
		params.append('reason', this.state.reason);
		//params.append('action', 'delete');

		await postApi('/api/v1/details', params);

		const y = await getApi('/api/v1/details/'+this.state.page);
    //console.log( y.data.Log );
		//this.setState({newshows: x.data.NewShows});
		this.setState({ logs: y.data.Log, waiting: false});

		

	}


  render() {
    //const user = this.context;
		
    return (
      <>
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							{this.state.logs &&
								<Card border="success" >
									<Card.Header>
										{this.state.logs.name}<br />
										{this.state.logs.delete === true &&
											<>
											<Form.Control as="textarea" rows={3} name="reason" value={this.state.reason} onChange={e => this.handleChange(e)} />
												{this.state.waiting===false &&
													<>
														<Button onClick={() => this.Delete()} variant="outline-danger">Delete</Button>
													</>
												}
											</>
										}
									</Card.Header>
									<Card.Body>
										<Card.Title>{this.state.logs.command}</Card.Title>
										<Card.Text>
											<Text>{this.state.logs.log}</Text>
										</Card.Text>
									</Card.Body>
								</Card>
							}
						</div>
					</div>
				</div>
    	</>
    );
  }
}

export default NewShows;
  