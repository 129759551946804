import React, { useContext, useEffect, /*useState,*/ Fragment } from 'react';
import {Link} from 'react-router-dom';
//import {useracc} from '../utils/functions';
import {UserContext} from '../utils/usercontext';

//import { Button } from './components/custom/button';


//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './components/fontawesome/fontawesome';


//className Header extends Component {
const Header = () => {
  
  const {user} = useContext(UserContext);

  

  var count = 0;
  if( user.user ) {
    count = 1;
  }
  useEffect(() => {
  }, []);




  return (
    <>
      {count ? (
        <>
        {user.user ?
          <>
          </>
          :
          <>
          </>
}
          
      
      
<div className="container-fluid">
	<ul className="nav justify-content-center">
		<li className="nav-item"><img src="https://ssmwsab.rocks/images/apple-touch-icon-144x144.png" width="24px" alt="header" /></li>
		<li className="nav-item"><Link className="nav-link" to="/">HOME</Link></li>
		<li className="nav-item"><Link className="nav-link" to="/newshows">NEW SHOWS</Link></li>
    <li className="nav-item"><Link className="nav-link" to="/upload">UPLOAD</Link></li>
    <li className="nav-item"><Link className="nav-link" to="/rss">RSS</Link></li>
    <li className="nav-item"><a className="nav-link" href="https://jb.ssmwsab.rocks">JB</a></li>
	</ul>
	
</div>
          
           
          
          
        </>
      ) : (
        <>
        </>
      )}
    </>
  );
}

export default Header;
