import React/*, {useState}*/ from 'react';
//import {View} from 'react-native'
import {Link} from 'react-router-dom';
import {useracc, getApi, postApi} from '../utils/functions';



//import Moment from 'react-moment';
import {UserContext} from '../utils/usercontext';

import ProgressBar from 'react-bootstrap/ProgressBar';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

//const {hasCommandModifier} = KeyBindingUtil;


//const Index = props => {
class Index extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      pagination: {},
      uploads: {},
      drives: {},
      loaded: false,

      search: '',
      page: 1,
    };
    this.SearchUploadsP = this.SearchUploadsP.bind(this);
    this.SearchUploads = this.SearchUploads.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  };

  //static contextType = UserContext;
  //loading:any = React.createRef();
  
  async componentDidMount() {
    //static contextType = AppContext;
    const value = this.context;

    //const [context, setContext] = useContext(UserContext);
    const getUser = await useracc();
    //console.log( getUser );
    //this.state.user = getUser;
    value.setUser( getUser );

    //console.log(this.context);

    //const [editorState, setEditorState] = useState('');
    const x = await getApi('https://jb007.ssmwsab.rocks/api/v1/index');
    //console.log( x.data );

    this.setState({ uploads: x.data.Uploads, drives: x.data.Drives, pagination: x.data.Pagination, loaded: true});

  }

  SearchUploadsP = async(e) => {
    var page = 0;
    if( e.target ) page = e.target.id;
    const params = new URLSearchParams();
    params.append('page', page );
    params.append('search', this.state.search );
    const x = await postApi('https://jb007.ssmwsab.rocks/api/v1/index', params);
    this.setState({page, uploads: x.data.Uploads, drives: x.data.Drives, pagination: x.data.Pagination});
  }

  handleSearch(event) {
    this.setState( {search: event.target.value } );
  }

  SearchUploads = async(e) => {
    e.preventDefault();
    //console.log(e);
    //var page = 0;
    //if( e.target ) page = e.target.id;
    const params = new URLSearchParams();
    params.append('page', this.state.page );
    params.append('search', this.state.search );
    const x = await postApi('https://jb007.ssmwsab.rocks/api/v1/index', params);
    console.log(x);
    this.setState({uploads: x.data.Uploads, drives: x.data.Drives, pagination: x.data.Pagination});
  }


  render() {
    //const user = this.context;

    const pagination = 
      Object.keys(this.state.pagination).map((e,i) => {
        return (
          <span key = {i}>
          {(() => {
            if( this.state.pagination[e].id > 0 ) {
              if (this.state.pagination[e].active==="true") {
                return (
                  <Button variant="outline-success" onClick={(e) => {this.SearchUploadsP(e)}} id={this.state.pagination[e].id}>{this.state.pagination[e].label}</Button>
                )
              } else {
                return (
                  <Button variant="outline-secondary" onClick={(e) => {this.SearchUploadsP(e)}} id={this.state.pagination[e].id} disabled>{this.state.pagination[e].label}</Button>
                )
              }
            }
          })()}
          </span>
        )
      })

    const uploads = 
      Object.keys(this.state.uploads).map((e,i) => {
        return (
          <React.Fragment key={i}>
          {this.state.uploads[e].delete===true ?
            <>
              <ListGroup.Item><Link to={`/details/${this.state.uploads[e].id}`}>{this.state.uploads[e].title}</Link></ListGroup.Item>
            </>
          :
            <>
              <del><ListGroup.Item><Link to={`/details/${this.state.uploads[e].id}`}>{this.state.uploads[e].title}</Link></ListGroup.Item></del>
            </>
          }
          </React.Fragment>
        )
      })

    const drives = 
      Object.keys(this.state.drives).map((e,i) => {
        var x = 'success';
        if( this.state.drives[e].used > 75 ) x = 'warning';
        if( this.state.drives[e].used > 90 ) x = 'danger';
        return (
          <div className="col-12" key = {i}>
            <div className="row">
              <div className="col-3">
                {e}
              </div>
              <div className="col-3">
                {this.state.drives[e].available} GB
              </div>
              <div className="col-6">
              <ProgressBar>
                <ProgressBar striped variant={x} now={this.state.drives[e].used} key={1} label={`${this.state.drives[e].used} %`} />
              </ProgressBar>
              </div>
            </div>
          </div>
        )
      })

    return (
      <>
        {this.state.loaded===true &&
     
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7">
                <div className="row">
                  <div className="col-6 text-center">
                    <Form noValidate  onSubmit={this.SearchUploads}>
                      <InputGroup className="mb-3">
                        <FormControl name="search" value={this.state.search} onChange={this.handleSearch} placeholder="" />
                        <Button variant="outline-secondary" id="button-addon2" type="submit">
                          Search
                        </Button>
                      </InputGroup>
                    </Form>
                  </div>
                  <div className="col-6">
                    {pagination}
                  </div>
                  <div className="col-12">
                    <ListGroup variant="flush">
                      {uploads}
                    </ListGroup>
                  </div>
                </div>

              </div>
              
              <div className="col-md-4">
                <div className="row">
                  <div className="col-12">
                    Drives
                  </div>
                  {drives}
                </div>
              </div>
            </div>
          </div>
        }
     
    	</>
    );
  }
}

export default Index;
  