import React/*, {useState}*/ from 'react';
import {useracc, postApi, putApi} from '../utils/functions';
import {UserContext} from '../utils/usercontext';
import Button from 'react-bootstrap/Button';

class RSS extends React.Component {
	static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
			loaded: false,
      code1: '',
			code2: '',
    };
		this.confirm = this.confirm.bind(this);
  };

	async componentDidMount() {
		const value = this.context;
		const getUser = await useracc();
    value.setUser( getUser );
		const params = new URLSearchParams();
    const x = await postApi('/api/v1/rssupdate', params);
		if( x.status === 200 ) {
    	this.setState({ loaded: true, code1: x.data.value, code2: x.data.value2});
		}
  }

	async confirm() {
		const params = new URLSearchParams();
		params.append('code', this.state.code1 );
    await putApi('/api/v1/rssupdate', params);
		this.componentDidMount();
	}

	render() {
		return (
			<>
        {this.state.loaded===true &&
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								Key 1: {this.state.code1}<br /><br />
								Key 2: {this.state.code2}<br /><br />
								{this.state.code1!==this.state.code2 &&
									<Button size="sm" onClick={this.confirm}>Confirm</Button>
								}
							</div>
						</div>
					</div>
				}
			</>
		)
	}
};

export default RSS;