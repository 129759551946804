import React from 'react';

const Logout = () => {
    window.localStorage.clear();
    window.location.href = '/';
    return (
      <>
        Logged out...
      </>
    );
}
  
export default Logout;