import React from 'react';
//import {Link} from 'react-router-dom';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../fontawesome/fontawesome';

const LoginForm = (props) => {
    return (
        <>
            <div className="bg-dark" style={{backgroundImage:'url(/assets/images/mb_bgr.jpg)', height:'100vh'}}>
                <div className="sufee-login d-flex align-content-center flex-wrap" style={{height:'100vh'}}>
                    <div className="container" style={{height:'100vh'}}>
                        <div className="login-content" style={{height:'100vh'}}>
                            <div className="login-form mt-140">
                                <h1 style={{color:'#fd0909',fontFamily:'serif',fontWeight: 'bold',textAlign:'center'}}>ADMIN PANEL</h1>
                                    <form method="post" onSubmit={props.getUser}>
                                        <div className="form-group">
                                            <label><b>USERNAME</b></label>
                                            <input type="text" name="uid" className="form-control" placeholder="" required />
                                        </div>
                                        <div className="form-group">
                                            <label><b>PASSWORD</b></label>
                                            <input type="password" name="pwd" className="form-control" placeholder="" required />
                                        </div>
                                        <input type="submit" value="ADMIN LOGIN" style={{backgroundColor:'#799893'}} disabled={props.await} className="btn btn-success btn-flat m-b-30 m-t-30"></input>
                                    </form>
                                <div className="field_error"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LoginForm;