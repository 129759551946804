import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


//---- Page includes
import Header from './routes/header';
import Footer from './routes/footer';
//---- Home page
import Index from './routes/index';
import NewShows from './routes/newshows';
import Details from './routes/details';
//---- Upload
import Upload from './routes/upload';
//---- RSS
import RSS from './routes/rss';

//---- Login/logout
import Login from './routes/login';
import Logout from './routes/logout';


//---- Components
import PrivateRoute from './components/privateroute';
import PublicRoute from './components/publicroute';
import {UserProvider} from './utils/usercontext';


class AlexeiReact extends Component {
  render() {
    return (
      <div style={{height: "95%"}}>
        <UserProvider>
          <Router>
            
            <Header />
            


            {/*---- Home page */}
            <PrivateRoute page="index" path="/" exact component={Index} />
            <PrivateRoute page="newshows" path="/newshows" exact component={NewShows} />
            <PrivateRoute page="details" path="/details/:id" exact component={Details} />
            <PrivateRoute page="upload" path="/upload" exact component={Upload} />
            <PrivateRoute page="upload" path="/rss" exact component={RSS} />

            
            {/*---- Login/logout/signup/recover */}
            <PublicRoute path="/login" exact component={Login} />
            <PublicRoute path="/logout" exact component={Logout} />


            <Footer />

            
          </Router>
        </UserProvider>
      </div>
    );
  }
};

export default AlexeiReact;
