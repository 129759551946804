//import React/*, { useContext, useEffect, useState, Fragment }*/ from 'react';
//import {UserContext} from '../utils/usercontext';

const TOKEN_COOKIE = 'cookie';
//const TOKEN_KEY = 'authorization';
const TOKEN_API = 'token';

export const cookie = (data) => {
    localStorage.setItem(TOKEN_COOKIE, data.cookie);
}

export const login = (data) => {
    if( data.error ) return;
    //localStorage.setItem(TOKEN_KEY, data.token);
}

//---- If first KEY fails, resort to API
export const keyfriends = (data) => {
    localStorage.setItem(TOKEN_API, data.friends);
}

export const logout = () => {
    //localStorage.removeItem(TOKEN_KEY);
}

export const IsLogin = () => {
    //const [user, setUser ] = useContext(UserContext);
    //if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    //}
    //return false;
}

export const PageAccess = (page, user) => {
    var result;
    if( !user.pages ) return true;
    switch( page ) {
        case 'index': result = true; break;
        case 'view_torrents': result = user.pages.view_torrents; break;
        case 'edit_torrents': result = user.pages.edit_torrents; break;
        case 'delete_torrents': result = user.pages.delete_torrents; break;
        case 'view_users': result = user.pages.view_users; break;
        case 'edit_users': result = user.pages.edit_users; break;
        case 'delete_users': result = user.pages.delete_users; break;
        case 'view_news': result = user.pages.view_news; break;
        case 'edit_news': result = user.pages.edit_news; break;
        case 'delete_news': result = user.pages.delete_news; break;
        case 'can_upload': result = user.pages.can_upload; break;
        case 'view_forum': result = user.pages.view_forum; break;
        case 'edit_forum': result = user.pages.edit_forum; break;
        case 'delete_forum': result = user.pages.delete_forum; break;
        default: result = true;
    }
    return result;
}

export const IsRestricted = (user, privilege) => {
    if( !IsLogin ) return false;
    if( !user.admin ) return false;
    if( user.admin.mod_access && privilege === "mod" ) return true;
    if( user.admin.admin_access && privilege === "admin" ) return true;
    return false;
}