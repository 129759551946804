import React, {useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IsLogin, PageAccess } from '../utils/protectedroutes';
import {UserContext} from '../utils/usercontext';

const PrivateRoute = ({component: Component, page, ...rest}) => {
    const {user} = useContext(UserContext);
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            IsLogin() ? PageAccess(page, user) ?
                <Component {...props} />
            : <Redirect to="/" />
            : <Redirect to="/login" />
        )} />
    );
};

export default PrivateRoute;