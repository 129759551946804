import React/*, {useState}*/ from 'react';
//import {View} from 'react-native'
import { Text } from 'react-native';
import {Link} from 'react-router-dom';
import {useracc, getApi/*, postApi*/} from '../utils/functions';



//import Moment from 'react-moment';
import {UserContext} from '../utils/usercontext';

import ListGroup from 'react-bootstrap/ListGroup';
//import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
//import Button from 'react-bootstrap/Button';

//const {hasCommandModifier} = KeyBindingUtil;


//const Index = props => {
class Upload extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
			upload: {},
			recent: {},

			viewlog: '',
    };
		this.reload = this.reload.bind(this);
		this.RequestRecent = this.RequestRecent.bind(this);
    //this.onEditorStateChange = this.onEditorStateChange.bind(this);
		//this.NewShowAdd = this.NewShowAdd.bind(this);
		//this.NewShowDelete = this.NewShowDelete.bind(this);
		//this.NewShowConfirm = this.NewShowConfirm.bind(this);
  };

  //static contextType = UserContext;
  //loading:any = React.createRef();
  
  async componentDidMount() {
    //static contextType = AppContext;
    const value = this.context;

    //const [context, setContext] = useContext(UserContext);
    const getUser = await useracc();
    //console.log( getUser );
    //this.state.user = getUser;
    value.setUser( getUser );

    //console.log(this.context);

    //const [editorState, setEditorState] = useState('');
    
		setInterval(this.reload, 10000, false);
    this.reload(true);
  }

	async reload(update) {
		const x = await getApi('/api/v1/upload');
		//console.log(x.data.Upload);
		this.setState({upload: x.data.Upload});
		if( update ) this.setState({recent:x.data.Recent});
	}

	async RequestRecent(torrent) {
		//console.log( torrent);
		await getApi('/api/v1/complete/'+torrent);
		this.reload(false);
	}

	renderStatus(status) {
    switch(status){
			case 0: return 'Pending';
      case 1: return 'Prepare Torrent';
      case 2: return 'Ready to Upload';
      case 3: return 'Uploading';
      case 9: return 'Complete';
      case 10: return 'Error';
      default: return '';
    }
  }

	Error(torrent) {
		//console.log(torrent);
		if( this.state.viewlog !== torrent ) {
			this.setState({viewlog:torrent});
		} else {
			this.setState({viewlog:''});
		}
		
	}

  render() {
    //const user = this.context;
		const upload = 
			Object.keys(this.state.upload).map((e,i) => {
				return (
					<React.Fragment key={i}>
						<ListGroup.Item>
							{this.state.upload[e].torrent_name} - {this.renderStatus(this.state.upload[e].status)}
							{this.state.upload[e].status === 10 && <>&nbsp;<Badge pill bg="primary" onClick={()=>this.Error(this.state.upload[e].torrent_name)}>Show/Hide</Badge></>}
						</ListGroup.Item>
						{this.state.viewlog === this.state.upload[e].torrent_name &&
							<ListGroup.Item disabled>
								<Text>{this.state.upload[e].logtext}</Text>
							</ListGroup.Item>
						}
					</React.Fragment>
				)
			})
		const recent = 
			Object.keys(this.state.recent).map((e,i) => {
				return (
					<React.Fragment key={i}>
						<ListGroup.Item>
							<Link to="#" onClick={() => this.RequestRecent(this.state.recent[e])}>{this.state.recent[e]}</Link>
						</ListGroup.Item>
					</React.Fragment>
				)
			})

    return (
      <>
      
     
      <div className="container-fluid" style={{height:'100%'}}>
        <div className="row" style={{height:'100%'}}>
          <div className="col-md-8">
                                        
            
						<ListGroup variant="flush">
              {upload}
            </ListGroup>
          
          
            
          </div>
					<div className="col-md-4" style={{height:'100%', overflowY:'scroll'}}>
						{recent}
					</div>
        </div>
      </div>
     
    	</>
    );
  }
}

export default Upload;
  