// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee, faTicketAlt, faBell, faEnvelope, faAngleDoubleDown, faVolumeUp, faVolumeMute, faUserCircle, faMinusCircle, faCircleNotch, faUser, faKey, faLock, faImage, faHourglassStart, faArrowUp, faArrowDown, faSync, faUpload, faDownload, faCoins, faRunning, faExpandArrowsAlt, faCompressArrowsAlt, faTimes, faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle as farUserCircle } from '@fortawesome/free-regular-svg-icons';

library.add(
    // not used
    fab,
    faCheckSquare,
    faCoffee,
    
    faTicketAlt, // header 
    faBell, // header
    faEnvelope, // header
    faAngleDoubleDown, // header
    faArrowUp, //submenu
    faArrowDown, //submenu
    faSync, //submenu
    faUpload, //submenu
    faDownload, //submenu
    faCoins, //submenu
    faRunning, //submenu
    faVolumeUp, // chatbox
    faVolumeMute, // chatbox
    faUserCircle,  // chatbox
    farUserCircle, // chatbox
    faMinusCircle, // chatbox
    faCircleNotch, // chatbox
    faChevronCircleDown, // chatbox
    faUser, // user
    faKey, // login
    faLock, // login
    faImage, // profile
    faHourglassStart, // syncerror

    faExpandArrowsAlt, // chatbox
    faCompressArrowsAlt, // chatbox
    faTimes, // chatbox

);

